<template>
  <v-container fluid class="pa-2">
    <div class="grey--text">{{ label }}</div>
    <v-text-field
      :id="'my-custom-input' + id"
      label="برای انتخاب کلیک کنید."
      :value="date | moment('jYYYY/jMM/jDD')"
      readonly
      :background-color="disabled ? 'grey' : 'grey lighten-2'"
      solo
      hide-details="auto"
      flat
      class="rounded-lg"
      :dense="dense"
      v-bind="$attrs"
      :rules="rules"
      :disabled="disabled"
    ></v-text-field>
    <date-picker
      v-model="date"
      format="YYYY-MM-DD HH:mm:ss"
      display-format="dddd jDD jMMMM jYYYY"
      :min="min"
      :max="max"
      :element="'my-custom-input' + id"
    >
    </date-picker>
  </v-container>
</template>

<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      modal: false,
    };
  },
  props: {
    value: {
      // v-model instance
      // type: String,
      required: true,
    },
    label: {
      required: true,
      type: String,
    },
    locale: {
      required: false,
      type: String,
      default: "fa-IR",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    id: {
      // for using multiple date picker in a page set unique id for each : by hossein
      type: String,
      default: 1,
    },
  },
  methods: {
    onOkClick() {
      this.$refs.dialog.save(this.date);
    },
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(date) {
        this.$emit("input", date);
      },
    },
  },
};
</script>

<style></style>
